import { AppConfig } from "../../AppConfig";

export const CHE_URLS = {
  CHE_GET_TEXTURE: `${AppConfig.api_url}product/v1/texture`,
  CHE_GET_LENGTH: `${AppConfig.api_url}product/v1/size`,
  CHE_GET_LACESIZE: `${AppConfig.api_url}product/v1/lace-size`,
  CHE_GET_LACEMATERIAL: `${AppConfig.api_url}product/v1/lace-material`,
  CHE_PRODUCT_NAME: `${AppConfig.api_url}product/v1/productName`,
  CHE_GET_CAPSIZE: `${AppConfig.api_url}product/v1/cap-size`,
  CHE_GET_WEIGHT: `${AppConfig.api_url}product/v1/weight`,
  CHE_GET_SHAPES: `${AppConfig.api_url}product/v1/shapes`,
  CHE_SAVE_ORDERS: `${AppConfig.api_url}order/v1/saveOrderDetails`,

  // PRODUCTS_LIST_ALL_GET: `${import.meta.env.VITE_APP_API_URL}product/v1/all`,
};
