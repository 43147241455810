import React, { useState, useEffect } from "react";
import "./OrderHere.css";
import orderHere from "../../assets/images/homepage/order/order.png";
import natrajar from "../../assets/images/homepage/order/n.png";
import {
  fetchCapSize,
  fetchLaceMaterial,
  fetchLaceSize,
  fetchLength,
  fetchProductsAll,
  fetchShapes,
  fetchTexture,
  fetchWeight,
  postApi,
} from "../../services/orderhere/orderhere.service";
import { showToast } from "../../utils/toast";
import { CountryDropdown } from "react-country-region-selector";

function OrderHere() {
  const [categoryOption, setCategoryOption] = useState("");
  const [productOption, setProductOption] = useState("");
  const [productOptionWigs, setProductOptionWigs] = useState("");
  const [productNameWigs, setProductNameWigs] = useState([
    "Closures",
    "Frontals",
  ]);
  const [products, setProducts] = useState(null);
  const [productName, setProductName] = useState([]);
  const [productNameWig, setProductNameWig] = useState("Bundles");
  const [textureOptions, setTextureOptions] = useState([]);
  const [selectedTexture, setSelectedTexture] = useState(null);
  const [lengthOptions, setLengthOptions] = useState([]);
  const [selectedLength, setSelectedLength] = useState(null);
  const [lengthOptionsForBundles, setLengthOptionsForBundles] = useState([]);
  const [selectedLengthWigs, setSelectedLengthWigs] = useState(null);
  const [
    lenghtOptionsForClosuresOrFrontals,
    setLenghtOptionsForClosuresOrFrontals,
  ] = useState([]);
  const [selectedLengthWigs2, setSelectedLengthWigs2] = useState(null);
  const [lsOptions, setLsOptions] = useState([]);
  const [selectedLs, setSelectedLs] = useState(null);
  const [lmOptions, setLmOptions] = useState([]);
  const [selectedLm, setSelectedLm] = useState(null);
  const [capSizeOptions, setCapSizeOptions] = useState([]);
  const [selectedCapSizeOptions, setSelectedCapSizeOptions] = useState(null);
  const [laceMaterialFrontalsOption, setLaceMaterialFrontalsOption] = useState(
    []
  );
  const [selectedLaceMaterialFrontals, setSelectedLaceMaterialFrontals] =
    useState(null);

  const [selectedWeight, setSelectedWeight] = useState(null);
  const [weightOptions, setWeightOptions] = useState([]);
  const [selectedShape, setSelectedShape] = useState(null);
  const [shapeOptions, setShapeOptions] = useState([]);
  const [selectedLaceSizeForClosures, setSelectedLaceSizeForClosures] =
    useState(null);
  const [laceSizeForClosuresOptions, setLaceSizeForClosuresOptions] = useState(
    []
  );
  const [selectedLaceSizeForFrontals, setSelectedLaceSizeForFrontals] =
    useState(null);
  const [laceSizeForFrontalsOptions, setLaceSizeForFrontalsOptions] = useState(
    []
  );
  const handleCountryCodeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      countryCode: e.target.value,
    }));
  };

  const [rowData, setRowData] = useState([]);

  const handleCategoryChange = (event) => {
    setCategoryOption(event.target.value);
    setProductOptionWigs("");
    setSelectedLengthWigs("");
    setSelectedLengthWigs2("");
    setProductOption("");
    setSelectedTexture("");
    setSelectedLength("");
    setSelectedLs("");
    setSelectedLm("");
    setSelectedCapSizeOptions("");
    setSelectedLaceMaterialFrontals("");
    setSelectedWeight("");
    setSelectedShape("");
    setSelectedLaceSizeForClosures("");
    setSelectedLaceSizeForFrontals("");
  };

  const handleproductChange = (event) => {
    setProductOption(event.target.value);
    setProductOptionWigs("");
    setSelectedLengthWigs("");
    setSelectedLengthWigs2("");
    setSelectedTexture("");
    setSelectedLength("");
    setSelectedLs("");
    setSelectedLm("");
    setSelectedCapSizeOptions("");
    setSelectedLaceMaterialFrontals("");
    setSelectedWeight("");
    setSelectedShape("");
    setSelectedLaceSizeForClosures("");
    setSelectedLaceSizeForFrontals("");
  };
  const handleproductChangeWigs = (event) => {
    setProductOptionWigs(event.target.value);
    setSelectedLaceSizeForClosures("");
    setSelectedLaceSizeForFrontals("");
    setSelectedLengthWigs2("");
    setSelectedLaceMaterialFrontals("");

    //  setSelectedTexture("");
    //  setSelectedLength("");
    //  setSelectedLs("");
    //  setSelectedLm("");
  };

  const handleTextureChange = (event) => {
    setSelectedTexture(event.target.value);
    setSelectedLength("");
    setSelectedLs("");
    setSelectedLm("");
    setSelectedLaceMaterialFrontals("");
  };

  const handleLengthChange = (event) => {
    setSelectedLength(event.target.value);
    setSelectedLs("");
    setSelectedLm("");
  };

  const handleLengthChange2 = (event) => {
    setSelectedLengthWigs(event.target.value);
    // setSelectedLs("");
    // setSelectedLm("");
  };
  const handleLengthChange3 = (event) => {
    setSelectedLengthWigs2(event.target.value);
    // setSelectedLs("");
    // setSelectedLm("");
  };

  const handleWeightChange = (event) => {
    setSelectedWeight(event.target.value);
  };

  const handleShapeChange = (event) => {
    setSelectedShape(event.target.value);
  };
  const handleLSChange = (event) => {
    setSelectedLs(event.target.value);
    setSelectedLm("");
  };

  const handleLMChange = (event) => {
    setSelectedLm(event.target.value);
  };

  const handleCapeSizeChange = (event) => {
    setSelectedCapSizeOptions(event.target.value);
  };
  const handleLaceMaterialFrontalsChange = (event) => {
    setSelectedLaceMaterialFrontals(event.target.value);
  };

  const handleLaceSizeChangeForClosures = (event) => {
    setSelectedLaceSizeForClosures(event.target.value);
  };

  const handleLaceSizeChangeForFrontals = (event) => {
    setSelectedLaceSizeForFrontals(event.target.value);
  };

  const getTexture = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
    };
    try {
      const response = await fetchTexture(payload);
      setTextureOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productOption) {
      getTexture();
    }
  }, [categoryOption, productOption]);

  const getlength = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
      productTexture: selectedTexture,
    };
    try {
      const response = await fetchLength(payload);
      setLengthOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productOption && selectedTexture) {
      getlength();
    }
  }, [categoryOption, productOption, selectedTexture]);

  const getCapSize = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
      productTexture: selectedTexture,
      productSize: selectedLength,
      productLaceSize: selectedLs,
    };
    try {
      const response = await fetchCapSize(payload);
      setCapSizeOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };
  useEffect(() => {
    if (
      categoryOption &&
      productOption &&
      selectedTexture &&
      selectedLength &&
      selectedLs
    ) {
      getCapSize();
    }
  }, [
    categoryOption,
    productOption,
    selectedTexture,
    selectedLength,
    selectedLs,
  ]);

  const getWeight = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
      productTexture: selectedTexture,
      productSize: selectedLength,
    };
    try {
      const response = await fetchWeight(payload);
      setWeightOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch weight for products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productOption && selectedTexture && selectedLength) {
      getWeight();
    }
  }, [categoryOption, productOption, selectedTexture, selectedLength]);

  const getshapes = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
      productTexture: selectedTexture,
      productSize: selectedLength,
    };
    try {
      const response = await fetchShapes(payload);
      setShapeOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch shapes for products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productOption && selectedTexture && selectedLength) {
      getshapes();
    }
  }, [categoryOption, productOption, selectedTexture, selectedLength]);

  const getLaceSizeForClosuresOrFrontals = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOptionWigs,
      productTexture: selectedTexture,
      productSize: selectedLengthWigs2,
    };
    try {
      const response = await fetchLaceSize(payload);
      if (productOptionWigs === "Closures") {
        setLaceSizeForClosuresOptions(response?.data?.data);
      } else {
        setLaceSizeForFrontalsOptions(response?.data?.data);
      }
    } catch (error) {
      console.error("Failed to fetch lace size for products:", error);
    }
  };
  useEffect(() => {
    if (
      categoryOption &&
      productOptionWigs &&
      selectedTexture &&
      selectedLengthWigs2
    ) {
      getLaceSizeForClosuresOrFrontals();
    }
  }, [categoryOption, productOptionWigs, selectedTexture, selectedLengthWigs2]);

  const getLaceMaterialForClosuresOrFrontals = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOptionWigs,
      productTexture: selectedTexture,
      productSize: selectedLengthWigs2,
      productLaceSize: selectedLaceSizeForFrontals,
    };
    try {
      const response = await fetchLaceMaterial(payload);
      setLaceMaterialFrontalsOption(response?.data?.data);
    } catch (error) {
      console.error("Failed to lace material products:", error);
    }
  };
  useEffect(() => {
    if (
      categoryOption &&
      productOptionWigs &&
      selectedTexture &&
      selectedLengthWigs2 &&
      selectedLaceSizeForFrontals
    ) {
      getLaceMaterialForClosuresOrFrontals();
    }
  }, [
    categoryOption,
    productOptionWigs,
    selectedTexture,
    selectedLengthWigs2,
    selectedLaceSizeForFrontals,
  ]);

  const getlengthForBundles = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productNameWig,
      productTexture: selectedTexture,
    };
    try {
      const response = await fetchLength(payload);
      setLengthOptionsForBundles(response?.data?.data);
    } catch (error) {
      console.error("Failed to length for bundles products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productNameWig && selectedTexture) {
      getlengthForBundles();
    }
  }, [categoryOption, productNameWig, selectedTexture]);

  const getlengthForClosuresOrFrontals = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOptionWigs,
      productTexture: selectedTexture,
    };
    try {
      const response = await fetchLength(payload);
      setLenghtOptionsForClosuresOrFrontals(response?.data?.data);
    } catch (error) {
      console.error("Failed to length for C/F products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productOptionWigs && selectedTexture) {
      getlengthForClosuresOrFrontals();
    }
  }, [categoryOption, productOptionWigs, selectedTexture]);

  const getLaceSIze = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
      productTexture: selectedTexture,
      productSize: selectedLength,
    };
    try {
      const response = await fetchLaceSize(payload);
      setLsOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };
  useEffect(() => {
    if (categoryOption && productOption && selectedTexture && selectedLength) {
      getLaceSIze();
    }
  }, [categoryOption, productOption, selectedTexture, selectedLength]);

  const getLaceMaterial = async () => {
    const payload = {
      productCategory: categoryOption,
      productName: productOption,
      productTexture: selectedTexture,
      productSize: selectedLength,
      productLaceSize: selectedLs,
    };
    try {
      const response = await fetchLaceMaterial(payload);
      setLmOptions(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };
  useEffect(() => {
    if (
      categoryOption &&
      productOption &&
      selectedTexture &&
      selectedLength &&
      selectedLs
    ) {
      getLaceMaterial();
    }
  }, [
    categoryOption,
    productOption,
    selectedTexture,
    selectedLength,
    selectedLs,
  ]);

  const isTextureDisabled = !(categoryOption && productOption);
  const islengthDisabled = !(
    categoryOption &&
    productOption &&
    selectedTexture
  );
  const islsDisabled = !(
    categoryOption &&
    productOption &&
    selectedTexture &&
    selectedLength
  );
  const islmDisabled = !(
    categoryOption &&
    productOption &&
    selectedTexture &&
    selectedLength &&
    selectedLs
  );

  const [count, setCount] = useState(1);
  const [countBundles, setCountBundles] = useState(1);
  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
  };

  const handleIncrementBundles = () => {
    setCountBundles(countBundles + 1);
  };

  const handleDecrementBundles = () => {
    setCountBundles((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
  };

  const deleteIcon = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 -960 960 960"
      width="18px"
      fill="#EA4335"
    >
      <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
    </svg>,
  ];
  // || !selectedLm
  function addData() {
    if (
      !categoryOption ||
      !productOption ||
      !selectedTexture ||
      !selectedLength ||
      (["Closures", "Frontals", "Wigs"].includes(productOption) &&
        !selectedLs) ||
      count <= 0
    ) {
      showToast("All fields are required!", "error");
      return;
    }

    let newItem = {
      category: categoryOption,
      Product: productOption,
      Texture: selectedTexture,
      Length: selectedLength,
      LaceSize: selectedLs,
      LaceMaterial: selectedLm,
      capSize: selectedCapSizeOptions,
      shape: selectedShape,
      weight: selectedWeight,
      Qts: count,
    };

    // Add Wig-specific fields only if productOption is "Wigs"
    if (productOption === "Wigs") {
      newItem = {
        ...newItem,
        ProductWig1: productNameWig,
        ProductLengthWig1: selectedLengthWigs,
        BundlesQty: countBundles,
        ProductWig2: productOptionWigs,
        ProductLengthWig2: selectedLengthWigs2,
        ProductLaceSizeForClosures: selectedLaceSizeForClosures,
        ProductLaceSizeForFrontals: selectedLaceSizeForFrontals,
        productLaceMaterial: selectedLaceMaterialFrontals,
      };
    }

    setRowData((prevRowData) => {
      const existingIndex = prevRowData.findIndex(
        (item) =>
          item.category === newItem.category &&
          item.Product === newItem.Product &&
          item.Texture === newItem.Texture &&
          item.Length === newItem.Length &&
          item.LaceSize === newItem.LaceSize &&
          item.LaceMaterial === newItem.LaceMaterial &&
          item.capSize === newItem.capSize &&
          item.shape === newItem.shape &&
          item.weight === newItem.weight &&
          (productOption !== "Wigs" || // Check these fields only if it's "Wigs"
            (item.ProductWig1 === newItem.ProductWig1 &&
              item.ProductLengthWig1 === newItem.ProductLengthWig1 &&
              item.ProductWig2 === newItem.ProductWig2 &&
              item.productLaceMaterial == newItem.productLaceMaterial &&
              item.ProductLengthWig2 === newItem.ProductLengthWig2 &&
              item.ProductLaceSizeForClosures ===
                newItem.ProductLaceSizeForClosures &&
              item.ProductLaceSizeForFrontals ===
                newItem.ProductLaceSizeForFrontals))
      );

      if (existingIndex !== -1) {
        // Update the quantity if the item exists
        const updatedRowData = prevRowData.map((item, index) =>
          index === existingIndex
            ? {
                ...item,
                Qts: item.Qts + newItem.Qts,
                ...(productOption === "Wigs" && {
                  BundlesQty: item.BundlesQty + newItem.BundlesQty,
                }),
              }
            : item
        );
        return updatedRowData;
      } else {
        return [...prevRowData, newItem];
      }
    });

    showToast("Order Item Successfully added!", "success");

    setTimeout(() => {
      setCategoryOption("");
      setProductOption("");
      setSelectedTexture("");
      setSelectedLength("");
      setSelectedLs("");
      setSelectedLm("");
      setCount(1);
      setSelectedCapSizeOptions("");
      setSelectedLaceMaterialFrontals("");
      setSelectedWeight("");
      setSelectedShape("");
      if (productOption === "Wigs") {
        setSelectedLengthWigs("");
        setCountBundles(1);
        setSelectedLengthWigs2("");
        setSelectedCapSizeOptions("");
        setSelectedLaceMaterialFrontals("");
        setSelectedLaceSizeForFrontals("");
        setSelectedLaceSizeForClosures("");
      }
    }, 500);
  }

  const deleteRow = (index) => {
    const newData = rowData?.filter((_, i) => i !== index);
    setRowData(newData);
    showToast("Order Item Successfully deleted", "success");
  };

  const getProducts = async () => {
    try {
      const response = await fetchProductsAll();
      setProducts(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const [formData, setFormData] = useState({
    fullname: "",
    mobileNumber: "",
    countryCode: "+1",
    mailAddress: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // for (const [key, value] of Object.entries(formData)) {
    //   if (!value) {
    //     showToast(
    //       `${key.charAt(0).toUpperCase() + key.slice(1)} is required`,
    //       "error"
    //     );
    //     return;
    //   }
    // }
    if (!formData?.fullname) {
      showToast("Name is Required", "error");
      return;
    }
    if (!formData?.mobileNumber) {
      showToast("Whatsapp Number is Required", "error");
      return;
    }
    if (!formData?.mailAddress) {
      showToast("E-mail is required", "error");
      return;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mailAddress)) {
      showToast("Please enter a valid email address!", "error");
      return;
    }

    if (!formData?.address) {
      showToast("Address is Required", "error");
      return;
    }
    if (!formData?.city) {
      showToast("City is Required", "error");
      return;
    }
    if (!formData?.state) {
      showToast("State is Required", "error");
      return;
    }
    if (!formData?.country) {
      showToast("Country is Required", "error");
      return;
    }
    if (!formData?.zipCode) {
      showToast("Zipcode is Required", "error");
      return;
    }

    if (rowData?.length === 0) {
      showToast("Please add at least one order item.", "error");
      return;
    }

    const payload = {
      ...formData,
      orderItem: rowData?.map((item) => ({
        isWigs: item.Product === "Wigs",
        product: {
          productCategory: item.category,
          productName: item.Product,
          productTexture: item.Texture,
          productSize: item.Length,
          ...(["Closures", "Wigs", "Frontals"].includes(item.Product) &&
            item.LaceSize && { productLaceSize: item.LaceSize }),
          ...(item.Product === "Wigs" &&
            item.capSize && { productCapSize: item.capSize }),
          ...(item.Product === "Frontals" &&
            item.LaceMaterial && { productMaterial: item.LaceMaterial }),
          ...(item.Product === "Clip Hair Extensions" &&
            item.weight && { productWeight: item.weight }),
          ...(item.Product === "Keratin Tips" &&
            item.shape && { productShapes: item.shape }),
        },
        productQuantity: item.Qts,
        ...(item.Product === "Wigs" && {
          bundles: {
            product: {
              productCategory: item.category,
              productName: item.ProductWig1,
              productTexture: item.Texture,
              productSize: item.ProductLengthWig1,
            },
            bundlesQuantity: item.BundlesQty,
          },

          closuresOrFrontals: {
            isClosures: item.ProductWig2 === "Closures",
            product: {
              productCategory: item.category,
              productName: item.ProductWig2,
              productTexture: item.Texture,
              productLaceSize: item.LaceSize,
              productSize: item.ProductLengthWig2,
              ...(item.ProductWig2 === "Frontals" &&
                item.productLaceMaterial && {
                  productMaterial: item.productLaceMaterial,
                }),
              ...(item.ProductWig2 === "Closures" &&
                item.ProductLaceSizeForClosures && {
                  productLaceSize: item.ProductLaceSizeForClosures,
                }),
              ...(item.ProductWig2 === "Frontals" &&
                item.ProductLaceSizeForFrontals && {
                  productLaceSize: item.ProductLaceSizeForFrontals,
                }),
            },
            closuresOrFrontalsQuantity: item.Qts,
          },
        }),
      })),
    };

    try {
      const response = await postApi(payload);
      if (response?.data) {
        showToast("Order submitted successfully!", "success");
        setFormData({
          fullname: "",
          mobileNumber: "",
          mailAddress: "",
          address: "",
          city: "",
          state: "",
          country: "",
          countryCode: "+1",
          zipCode: "",
        });

        setRowData([]);
      } else {
        showToast("Failed to submit order. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      showToast("Failed to submit order", "error");
    }
  };

  return (
    <>
      <div id="order-here" className="hairsindia-order-container relative flex">
        <div className="hairsindia-order-content">
          <div className="flex flex-col md:flex-row">
            <img src={natrajar} className="hairsindia-natrajar" />
            <img
              src={orderHere}
              alt="title"
              className="hairsindia-order-title"
            />
          </div>
          <div className="flex justify-center w-full">
            <div className="hairsindia-order-list-container flex gap-[20px] justify-between items-start w-[85%]">
              <div className="hairsindia-order-content-left flex flex-col justify-center gap-[20px]">
                <div className="chi-select-bx flex items-center gap-[6px] md:gap-[20px] flex-wrap">
                  <div className="selection-section flex flex-col">
                    <label htmlFor="categoryOption">Category</label>
                    <select
                      id="categoryOption"
                      value={categoryOption}
                      onChange={handleCategoryChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="613">613</option>
                      <option value="Black">Black</option>
                      <option value="Grey">Grey</option>
                    </select>
                  </div>
                  <div className="selection-section flex flex-col">
                    <label htmlFor="productOption">Product</label>
                    <select
                      id="productOption"
                      value={productOption}
                      onChange={(e) => handleproductChange(e)}
                      aria-placeholder="select Product"
                      className="custom-product-option"
                    >
                      <option value="" disabled selected>
                        Select
                      </option>

                      {products?.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="selection-section flex flex-col">
                    <label htmlFor="textureOptions">Texture</label>
                    <select
                      id="textureOptions"
                      value={selectedTexture}
                      onChange={(e) => handleTextureChange(e)}
                      disabled={isTextureDisabled}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>

                      {textureOptions?.map((texture, index) => (
                        <option key={index} value={texture}>
                          {texture}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="selection-section flex flex-col">
                    <label htmlFor="lengthOption">Length</label>
                    <select
                      id="lengthOption"
                      value={selectedLength}
                      onChange={(e) => handleLengthChange(e)}
                      disabled={islengthDisabled}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {lengthOptions?.map((data, index) => (
                        <option key={index} value={data?.value}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="chi-select-bx flex items-center gap-[6px] md:gap-[20px] flex-wrap">
                  {(productOption === "Closures" ||
                    productOption === "Frontals" ||
                    productOption === "Wigs") && (
                    <>
                      <div className="selection-section flex flex-col">
                        <label htmlFor="selectOptions">Lace Size</label>
                        <select
                          id="selectOptions"
                          value={selectedLs}
                          onChange={handleLSChange}
                          disabled={islsDisabled}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>

                          {lsOptions?.map((data, index) => (
                            <option key={index} value={data?.value}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </div>
                      {productOption === "Frontals" ? (
                        <div className="selection-section flex flex-col">
                          <label htmlFor="LaceMaterial">Lace Material</label>
                          <select
                            id="LaceMaterial"
                            value={selectedLm}
                            onChange={handleLMChange}
                            disabled={islmDisabled}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            {lmOptions?.map((data, index) => (
                              <option key={index} value={data?.value}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : productOption === "Wigs" ? (
                        <div className="selection-section flex flex-col">
                          <label htmlFor="capSize">Cap Size</label>
                          <select
                            id="capSize"
                            value={selectedCapSizeOptions}
                            onChange={handleCapeSizeChange}
                            disabled={islmDisabled}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            {capSizeOptions?.map((data, index) => (
                              <option key={index} value={data?.value}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}
                    </>
                  )}
                  {productOption === "Clip Hair Extensions" && (
                    <div className="selection-section flex flex-col">
                      <label htmlFor="weight">Weight</label>
                      <select
                        id="weight"
                        value={selectedWeight}
                        onChange={(e) => handleWeightChange(e)}
                        disabled={!selectedLength}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {weightOptions?.map((data, index) => (
                          <option key={index} value={data?.value}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {productOption === "Keratin Tips" && (
                    <div className="selection-section flex flex-col">
                      <label htmlFor="shapes">Shapes</label>
                      <select
                        id="shapes"
                        value={selectedShape}
                        onChange={(e) => handleShapeChange(e)}
                        disabled={!selectedLength}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        shapes
                        {shapeOptions?.map((data, index) => (
                          <option key={index} value={data?.value}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="hairsindia-order-counter">
                    <label>No.of Pcs</label>
                    <div className="order-numbers flex">
                      <span className="order-minus" onClick={handleDecrement}>
                        -
                      </span>
                      <input type="text" value={count} readOnly />
                      <span className="order-plus" onClick={handleIncrement}>
                        +
                      </span>
                    </div>
                  </div>
                </div>
                <div className="chi-select-bx flex items-center gap-[6px] md:gap-[20px] flex-wrap">
                  {productOption === "Wigs" && (
                    <>
                      <div className="selection-section flex flex-col">
                        <label htmlFor="ProductWig">Bundles for Wigs</label>
                        <select
                          id="ProductWig"
                          value={productNameWig}
                          // onChange={(e) => setProductNameWig("Bundles")} // Always set to "Bundles"
                          // disabled
                        >
                          <option value="Bundles">Bundles</option>
                        </select>
                      </div>
                      <div className="selection-section flex flex-col">
                        <label htmlFor="lengthOption2">
                          Length for Bundles
                        </label>
                        <select
                          id="lengthOption2"
                          value={selectedLengthWigs}
                          onChange={(e) => handleLengthChange2(e)}
                          disabled={islengthDisabled}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {lengthOptionsForBundles?.map((data, index) => (
                            <option key={index} value={data?.value}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="hairsindia-order-counter">
                        <label>No.of Bundles</label>
                        <div className="order-numbers flex">
                          <span
                            className="order-minus"
                            onClick={handleDecrementBundles}
                          >
                            -
                          </span>
                          <input type="text" value={countBundles} readOnly />
                          <span
                            className="order-plus"
                            onClick={handleIncrementBundles}
                          >
                            +
                          </span>
                        </div>
                      </div>

                      <div className="selection-section flex flex-col">
                        <label htmlFor="productOption2">
                          Closures/Frontals
                        </label>
                        <select
                          id="productOption2"
                          value={productOptionWigs}
                          onChange={(e) => handleproductChangeWigs(e)}
                          aria-placeholder="select Product"
                          className="custom-product-option"
                        >
                          <option value="" disabled selected>
                            Select
                          </option>

                          {productNameWigs?.map((product, index) => (
                            <option key={index} value={product}>
                              {product}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="selection-section flex flex-col">
                        <label htmlFor="lengthOption3">Length for C/F</label>
                        <select
                          id="lengthOption3"
                          value={selectedLengthWigs2}
                          onChange={(e) => handleLengthChange3(e)}
                          disabled={!productOptionWigs}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {lenghtOptionsForClosuresOrFrontals?.map(
                            (data, index) => (
                              <option key={index} value={data?.value}>
                                {data}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {productOptionWigs === "Closures" ? (
                        <div className="selection-section flex flex-col">
                          <label htmlFor="ClosuresLaceSize">
                            Closures Lace Size
                          </label>
                          <select
                            id="ClosuresLaceSize"
                            value={selectedLaceSizeForClosures}
                            onChange={(e) => handleLaceSizeChangeForClosures(e)}
                            aria-placeholder="select Lace Size"
                            className="custom-product-option"
                            disabled={!selectedLengthWigs2}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>

                            {laceSizeForClosuresOptions?.map((data, index) => (
                              <option key={index} value={data?.value}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : productOptionWigs === "Frontals" ? (
                        <div className="selection-section flex flex-col">
                          <label htmlFor="FrontalsLaceSize">
                            Frontals Lace Size
                          </label>
                          <select
                            id="FrontalsLaceSize"
                            value={selectedLaceSizeForFrontals}
                            onChange={(e) => handleLaceSizeChangeForFrontals(e)}
                            aria-placeholder="select Lace Size"
                            className="custom-product-option"
                            disabled={!selectedLengthWigs2}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>

                            {laceSizeForFrontalsOptions?.map((data, index) => (
                              <option key={index} value={data?.value}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}

                      {productOptionWigs === "Frontals" && (
                        <div className="selection-section flex flex-col">
                          <label htmlFor="selectOptions">Lace Material</label>
                          <select
                            id="selectOptions"
                            value={selectedLaceMaterialFrontals}
                            onChange={handleLaceMaterialFrontalsChange}
                            disabled={!selectedLaceSizeForFrontals}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            {laceMaterialFrontalsOption?.map((data, index) => (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </>
                  )}
                  <button
                    className="hairsindia-orders-add-btn"
                    onClick={() => addData()}
                  >
                    ADD
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped bg-white text-sm">
                    <thead>
                      <tr>
                        <th className="text-center">S.No</th>
                        <th className="text-left">Category</th>
                        <th className="text-left">Product</th>
                        <th className="text-left">Texture</th>
                        <th className="text-left">Length</th>
                        <th className="text-left w-[3%]">Lace Size</th>
                        <th className="text-left w-[3%]">Lace Material</th>
                        <th className="text-left">Cap Size</th>
                        {rowData.some((row) => row.shape) && (
                          <th className="text-left">Shape</th>
                        )}
                        {rowData.some((row) => row.weight) && (
                          <th className="text-left">Weight</th>
                        )}
                        {/* Conditionally render column headers */}

                        {rowData.some((row) => row.ProductWig1) && (
                          <th className="text-left">Bundles Wigs</th>
                        )}
                        {rowData.some((row) => row.ProductLengthWig1) && (
                          <th className="text-left">Length Bundles</th>
                        )}
                        {rowData.some((row) => row.BundlesQty) && (
                          <th className="text-left">No. of Bundles</th>
                        )}
                        {rowData.some((row) => row.ProductWig2) && (
                          <th className="text-left">Closures/Frontals</th>
                        )}

                        {rowData.some((row) => row.ProductLengthWig2) && (
                          <th className="text-left">Length for C/F</th>
                        )}
                        {rowData.some(
                          (row) => row.ProductWig2 === "Closures"
                        ) && <th className="text-left">Closures Lace Size</th>}
                        {rowData.some(
                          (row) => row.ProductWig2 === "Frontals"
                        ) && <th className="text-left">Frontals Lace Size</th>}

                        {rowData.some(
                          (row) => row.ProductWig2 === "Frontals"
                        ) && <th className="text-left">Frontals Material</th>}
                        <th className="text-left">Qty</th>
                        <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody className="product-body">
                      {rowData?.length === 0 ? (
                        <tr>
                          <td
                            colSpan="10"
                            className="text-start sm:text-center text-sm"
                          >
                            Please add a Product
                          </td>
                        </tr>
                      ) : (
                        rowData?.map((row, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-left">{row.category}</td>
                            <td className="text-left">{row.Product}</td>
                            <td className="text-left">{row.Texture}</td>
                            <td className="text-left">{row.Length}</td>
                            <td className="text-left w-[3%]">
                              {row.LaceSize || "-"}
                            </td>
                            <td className="text-left w-[3%]">
                              {row.LaceMaterial || "-"}
                            </td>
                            <td className="text-left">{row.capSize || "-"}</td>

                            {/* Conditionally render Shape and Weight columns */}
                            {rowData.some((row) => row.shape) ? (
                              <td className="text-left">{row.shape || "-"}</td>
                            ) : null}
                            {rowData.some((row) => row.weight) ? (
                              <td className="text-left">{row.weight || "-"}</td>
                            ) : null}

                            {/* Ensure table cells match headers */}
                            {rowData.some((row) => row.ProductWig1) ? (
                              <td className="text-left">
                                {row.ProductWig1 || "-"}
                              </td>
                            ) : null}
                            {rowData.some((row) => row.ProductLengthWig1) ? (
                              <td className="text-left">
                                {row.ProductLengthWig1 || "-"}
                              </td>
                            ) : null}
                            {rowData.some((row) => row.BundlesQty) ? (
                              <td className="text-left">
                                {row.BundlesQty || "-"}
                              </td>
                            ) : null}
                            {rowData.some((row) => row.ProductWig2) ? (
                              <td className="text-left">
                                {row.ProductWig2 || "-"}
                              </td>
                            ) : null}

                            {rowData.some((row) => row.ProductLengthWig2) ? (
                              <td className="text-left">
                                {row.ProductLengthWig2 || "-"}
                              </td>
                            ) : null}
                            {rowData.some(
                              (row) => row.ProductLaceSizeForClosures
                            ) ? (
                              <td className="text-left">
                                {row.ProductLaceSizeForClosures || "-"}
                              </td>
                            ) : null}
                            {rowData.some(
                              (row) => row.ProductLaceSizeForFrontals
                            ) ? (
                              <td className="text-left">
                                {row.ProductLaceSizeForFrontals || "-"}
                              </td>
                            ) : null}
                            {rowData.some((row) => row.productLaceMaterial) ? (
                              <td className="text-left">
                                {row.productLaceMaterial || "-"}
                              </td>
                            ) : null}

                            <td className="text-left">{row.Qts}</td>
                            <td className="text-right">
                              <button onClick={() => deleteRow(index)}>
                                {deleteIcon}
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="hairsindia-content-right flex flex-col gap-[6px]">
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    Name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    value={formData?.fullname}
                    onChange={handleInputChange}
                  />
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    Whatsapp Number
                  </label>
                  <div className="flex items-center">
                    <select
                      id="selectOptions"
                      value={formData.countryCode}
                      onChange={handleCountryCodeChange}
                    >
                      <option value="+1">+1</option>
                      <option value="+2">+2</option>
                      <option value="+3">+3</option>
                      <option value="+4">+4</option>
                      <option value="+5">+5</option>
                      <option value="+6">+6</option>
                      <option value="+7">+7</option>
                    </select>
                    <input
                      type="number"
                      className="w-full"
                      name="mobileNumber"
                      min="0"
                      value={formData?.mobileNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    E-mail
                  </label>
                  <input
                    type="email"
                    name="mailAddress"
                    value={formData?.mailAddress}
                    onChange={handleInputChange}
                  />
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    Address
                  </label>
                  <textarea
                    name="address"
                    value={formData?.address}
                    onChange={handleInputChange}
                  />
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData?.city}
                    onChange={handleInputChange}
                  />
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={formData?.state}
                    onChange={handleInputChange}
                  />
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    Country
                  </label>
                  <CountryDropdown
                    value={formData?.country}
                    onChange={(val) =>
                      setFormData((prevData) => ({ ...prevData, country: val }))
                    }
                  />
                </span>
                <span className="form-group flex flex-col">
                  <label className="text-[#fff] text-base font-normal">
                    Zipcode
                  </label>
                  <input
                    type="number"
                    name="zipCode"
                    value={formData?.zipCode}
                    onChange={handleInputChange}
                  />
                </span>
                <div className="flex justify-end">
                  <button
                    className="hairsindia-get-quote"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    GET A QUOTE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderHere;
