import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastStyles = {
  success: {
    background: "#28a745",
    color: "#fff",
  },
  error: {
    background: "rgb(228 68 83)",
    color: "#fff",
  },
  warning: {
    background: "#ffc107",
    color: "#000",
  },
  info: {
    background: "#17a2b8",
    color: "#fff",
  },
};

export const showToast = (message, type = "info") => {
  toast(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: false,
    theme: "colored",
    style: toastStyles[type] || toastStyles.info,
  });
};
