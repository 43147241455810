import React, { useRef, useState, useEffect } from "react";
import "./Products.css";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import img1 from "../../assets/images/homepage/product/img1.png";
import img2 from "../../assets/images/homepage/product/img2.png";
import img3 from "../../assets/images/homepage/product/img3.png";
import img4 from "../../assets/images/homepage/product/img4.png";
import img5 from "../../assets/images/homepage/product/img5.png";
import img6 from "../../assets/images/homepage/product/img6.png";
import img7 from "../../assets/images/homepage/product/img7.png";
import img8 from "../../assets/images/homepage/product/img8.png";
import img9 from "../../assets/images/homepage/product/img9.png";
import prod_nat from "../../assets/images/homepage/product/n.png";
import Aos from "aos";
import "aos/dist/aos.css";
import "animate.css/animate.compat.css";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useNavigate } from "react-router-dom";

function Products() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    const handleResize = () => {
      Aos.refresh();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [navigationButtonHide, setNavigationButtonHide] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      setNavigationButtonHide({
        isEnd: swiperRef?.current?.swiper?.isEnd,
        isBeginning: swiperRef?.current?.swiper?.isBeginning,
      });
    }
  };
  const handlePrevButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      setNavigationButtonHide({
        isEnd: swiperRef?.current?.swiper?.isEnd,
        isBeginning: swiperRef?.current?.swiper?.isBeginning,
      });
    }
  };

  const product = [
    { title: "Raw Hair Bundles", anchor: "raw-hair-bundles" },
    { title: "Frontals & closures", anchor: "frontals-closures" },
    { title: "Wigs", anchor: "wigs" },
    { title: "Seamless Clip Ins", anchor: "seamless-clip-ins" },
    { title: "Tap Ins", anchor: "tap-ins" },
    { title: "K Tips", anchor: "k-tips" },
    { title: "Grey Hair Extensions", anchor: "grey-hair-extensions" },
    { title: "Bleached Colors", anchor: "bleached-colors" },
    { title: "Raw Bulk Hair", anchor: "raw-bulk-hair" },
  ];

  const rawHair = [{ image: img3 }, { image: img3 }];
  const frontal = [{ image: img6 }, { image: img6 }];
  const wigs = [{ image: img7 }];
  const seamless = [{ image: img4 }, { image: img4 }];
  const tape = [{ image: img5 }, { image: img5 }];
  const ktips = [{ image: img2 }, { image: img2 }];
  const grey = [{ image: img1 }, { image: img1 }, { image: img1 }];
  const bleach = [{ image: img8 }, { image: img8 }, { image: img8 }];
  const bulk = [{ image: img9 }, { image: img9 }];

  const [bigImage, setBigImage] = useState(rawHair[0]?.image);
  const handleImageClick = (image) => {
    setBigImage(image);
  };

  const [frontalImage, setFrontalImage] = useState(frontal[0]?.image);
  const handlefrontalImageClick = (image) => {
    setFrontalImage(image);
  };
  const [wigimage, setWigImage] = useState(wigs[0]?.image);
  const handleWigImageClick = (image) => {
    setWigImage(image);
  };
  const [seamlessImage, setSeamlessImage] = useState(seamless[0]?.image);
  const handleSeamlessImageClick = (image) => {
    setSeamlessImage(image);
  };
  const [tapeImage, setTapeImage] = useState(tape[0]?.image);
  const handleTapeImageClick = (image) => {
    setTapeImage(image);
  };
  const [ktipsImage, setKtipsImage] = useState(ktips[0]?.image);
  const handleKtipsImageClick = (image) => {
    setKtipsImage(image);
  };
  const [greyImage, setGreyImage] = useState(grey[0]?.image);
  const handleGreyImageClick = (image) => {
    setGreyImage(image);
  };
  const [bleachImage, setBleachImage] = useState(bleach[0]?.image);
  const handleBleachImageClick = (image) => {
    setBleachImage(image);
  };
  const [bulkImage, setBulkImage] = useState(bulk[0]?.image);
  const handleBulkImageClick = (image) => {
    setBulkImage(image);
  };

  const scrollToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const arrowIcon = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 860 960"
      width="24px"
      fill="#fff"
      className="arrow-icon"
    >
      <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
    </svg>,
  ];
  return (
    <>
      <div
        id="product"
        className="hairsindia-product-container relative bg-[#6BB40E] py-[30px] sm:py-[40px] px-[60px] 2xl:px-[100px] gap-[15px] lg:gap-[10px]"
      >
        <Swiper
          ref={swiperRef}
          edgeSwipeDetection
          style={{ width: "100%" }}
          modules={[Navigation, A11y]}
          pagination={false}
          scrollbar={false}
          spaceBetween={20}
          slidesPerView="auto"
          noSwiping={navigationButtonHide.isEnd}
          onBeforeInit={(item) => {
            if (item) {
              setNavigationButtonHide({
                isEnd: item?.isEnd,
                isBeginning: item?.isBeginning,
              });
            }
          }}
          onSlideChange={(item) => {
            setNavigationButtonHide({
              isEnd: item?.isEnd,
              isBeginning: item?.isBeginning,
            });
          }}
          roundLengths="true"
        >
          {product?.map((item) => (
            <SwiperSlide
              className="hairsindia-swiper-product text-sm sm:text-base text-[#000] bg-white px-[8px] py-[3px] sm:px-[12px] sm:py-[4px] cursor-pointer"
              onClick={() => scrollToAnchor(item.anchor)}
            >
              {item?.title}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="hairsindia-product-swiper-btns flex justify-between items-center w-full">
          {!navigationButtonHide.isBeginning && (
            <button
              className="foundation-card-prev"
              onClick={handlePrevButtonClick}
            >
              {arrowIcon}
            </button>
          )}
          {!navigationButtonHide.isEnd && (
            <button
              className="foundation-card-next"
              onClick={handleNextButtonClick}
            >
              {arrowIcon}
            </button>
          )}
        </div>
      </div>
      <div
        id="raw-hair-bundles"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Raw</span>
          <span>Hair</span>
          <span>Bundles</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Raw</span>
            <span>Hair</span>
            <span>Bundles</span>
          </div>
          <div className="big-image-container">
            <InnerImageZoom
              src={bigImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>

          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {rawHair?.map((item, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleImageClick(item.image)}
              >
                <img
                  src={item.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="frontals-closures"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Frontals</span>
          <span>and</span>
          <span>Closures</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Frontals</span>
            <span>and</span>
            <span>Closures</span>
          </div>
          <div className="big-image-container frontal">
            <InnerImageZoom
              src={frontalImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {frontal?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handlefrontalImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="wigs"
        className="hairsindia-product wigs-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Wigs</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Wigs</span>
          </div>
          <div className="big-image-container hairsindia-wigs">
            <InnerImageZoom
              src={wigimage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image-wig"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {wigs?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleWigImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="seamless-clip-ins"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Seamless</span>
          <span>Clip Ins</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Seamless</span>
            <span>Clip Ins</span>
          </div>
          <div className="big-image-container">
            <InnerImageZoom
              src={seamlessImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {seamless?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleSeamlessImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="tap-ins"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Tape</span>
          <span>Ins</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Tape</span>
            <span>Ins</span>
          </div>
          <div className="big-image-container taps-ins">
            <InnerImageZoom
              src={tapeImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {tape?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleTapeImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="k-tips"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>K Tips</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>K Tips</span>
          </div>
          <div className="big-image-container">
            <InnerImageZoom
              src={ktipsImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {ktips?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleKtipsImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="grey-hair-extensions"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Grey</span>
          <span>Hair</span>
          <span>Extensions</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Grey</span>
            <span>Hair</span>
            <span>Extensions</span>
          </div>
          <div className="big-image-container">
            <InnerImageZoom
              src={greyImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row lg:flex-col gap-[20px] ">
            {grey?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleGreyImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="bleached-colors"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Bleached</span>
          <span>Colors</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Bleached</span>
            <span>Colors</span>
          </div>
          <div className="big-image-container">
            <InnerImageZoom
              src={bleachImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {bleach?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleBleachImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>

      <div
        id="raw-bulk-hair"
        className="hairsindia-product flex justify-center items-center relative"
      >
        <img src={prod_nat} className="hairsindia-prod-nat" />
        <div
          className="hairsindia-product-text absolute wow bounceInDown"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <span>Raw</span>
          <span>Bulk</span>
          <span>Hair</span>
        </div>
        <div className="hairsindia-product-image-section flex justify-center items-center gap-[20px] xl:mr-[70px] lg:mt-[10px] relative">
          <div className="hairsindia-product-text-section">
            <span>Raw</span>
            <span>Bulk</span>
            <span>Hair</span>
          </div>
          <div className="big-image-container ">
            <InnerImageZoom
              src={bulkImage}
              width="100%"
              height="100%"
              zoomWidth={500}
              imgStyle={{
                maxWidth: "100%",
                height: "auto",
              }}
              zoomStyle="border: 1px solid #d4d4d4;"
              className="hairsindia-product-big-image"
            />
          </div>
          <span className="flex flex-row gap-[20px] lg:flex-col ">
            {bulk?.map((data, index) => (
              <div
                key={index}
                className="small-image-container"
                onClick={() => handleBulkImageClick(data?.image)}
              >
                <img
                  src={data?.image}
                  alt="small_image"
                  className="hairsindia-product-small-image"
                />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="sections-divider"></div>
    </>
  );
}
export default Products;
