import React, { useState, useEffect } from "react";
import "./Header.css";
import logo from "../../../assets/images/header/logo1.png";
import { NavLink, useNavigate } from "react-router-dom";
import title_image from "../../../assets/images/header/title.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import "animate.css/animate.compat.css";
import "animate.css/animate.min.css";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

function Header() {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,

      resetAnimation: false,
    });
    wow.init();
  }, []);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      setIsMobileMenuOpen(false);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const arrowIcon = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="20px"
      fill="#fff"
    >
      <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
    </svg>,
  ];

  return (
    <div className="hairsindia-header-container relative re flex flex-col items-center gap-[10px] mt-[30px] mb-[20px]">
      <div className="hairsindia-header-top flex justify-center items-end">
        <img
          className="header-logo-image wow zoomIn"
          src={logo}
          alt="logo_image"
        />
        <div className="hairsindia-header-top-content flex flex-col items-center gap-[5px]">
          <img
            className="header-title-image wow fadeInDown"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
            src={title_image}
          />
          <h3
            className=" Indian-Human-Hair-Exports text-heading6 font-semibold leading-[27px]"
            style={{ fontFamily: "var(--font-family2)" }}
          >
            Indian Human Hair Exports
          </h3>
        </div>
      </div>
      <nav className="hairsindia-header-menu">
        <ul>
          <li>
            <NavLink
              exact
              className="nav-link"
              onClick={() => scrollToAnchor("homepage")}
              to="/chennaihairsindia/homepage"
            >
              Home
            </NavLink>
          </li>
          <span></span>
          <li>
            <NavLink
              className="nav-link"
              onClick={() => scrollToAnchor("who-we-are")}
            >
              Who We Are
            </NavLink>
          </li>
          <span></span>
          <li>
            <NavLink
              className="nav-link"
              onClick={() => scrollToAnchor("product")}
            >
              Products
            </NavLink>
          </li>
          <span></span>
          <li>
            <NavLink
              className="nav-link"
              onClick={() => scrollToAnchor("enquire-us")}
            >
              Enquire
            </NavLink>
          </li>
        </ul>
        <button
          className="hairsindia-order-here-btn"
          // onClick={() =>
          //   (window.location.href =
          //     "https://orders.chennaihairsindia.com/index.php/home/get_quote")
          // }
          onClick={() => navigate("/order")}
        >
          Order Here
        </button>
        {/* <NavLink to="/reviews" className="hairsindia-order-reviews-btn">
          Reviews
        </NavLink> */}
        <button
          onClick={() => scrollToAnchor("reviews")}
          className="hairsindia-order-reviews-btn"
        >
          Reviews
        </button>
      </nav>

      <div className="hairsindia-menu-mobile-view">
        {/* <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          ☰
        </button> */}
        <button
          className={`mobile-menu-toggle ${isMobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
        >
          {toggleMobileMenu ? arrowIcon : ""}
          {}
        </button>
        <div
          className={`mobile-menu-overlay ${isMobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
        ></div>
        <nav
          className={`hairsindia-header-menu-mobile ${
            isMobileMenuOpen ? "open" : ""
          }`}
        >
          <ul>
            <li>
              <NavLink
                exact
                className="nav-link"
                onClick={() => scrollToAnchor("homepage")}
              >
                Home
              </NavLink>
            </li>
            <span></span>
            <li>
              <NavLink
                className="nav-link"
                onClick={() => scrollToAnchor("who-we-are")}
              >
                Who We Are
              </NavLink>
            </li>
            <span></span>
            <li>
              <NavLink
                className="nav-link"
                onClick={() => scrollToAnchor("product")}
              >
                Products
              </NavLink>
            </li>
            <span></span>
            <li>
              <NavLink
                className="nav-link"
                onClick={() => scrollToAnchor("enquire-us")}
              >
                Enquire
              </NavLink>
            </li>
          </ul>
          <button
            className="hairsindia-order-here-btn"
            onClick={() =>
              (window.location.href =
                "https://orders.chennaihairsindia.com/index.php/home/get_quote")
            }
          >
            Order Here
          </button>

          {/* <NavLink to="/chennaihairsindia/reviews" className="hairsindia-order-reviews-btn">
            Reviews
          </NavLink> */}
          <button
            onClick={() => scrollToAnchor("reviews")}
            className="hairsindia-order-reviews-btn"
          >
            Reviews
          </button>
        </nav>
      </div>
    </div>
  );
}
export default Header;
