import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosService = {
  get: (url, params) => axiosInstance.get(url, { params }),

  getFileAll: (url) =>
    axiosInstance.get(url, { responseType: "arraybuffer" }),

  getFileById: (url, params) =>
    axiosInstance.get(url, { responseType: "arraybuffer", params }),

  post: (url, data) => axiosInstance.post(url, data),

  put: (url, data) => axiosInstance.put(url, data),

  delete: (url) => axiosInstance.delete(url),

  deleteMulti: (url, payload) =>
    axiosInstance.delete(url, { data: payload }),
};
