import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import "./utils/style/base.css";
import HairsIndiaHomepage from "./modules/hairsindia-homepage/HairsIndiaHomepage";
import "./modules/hairsindia-homepage/HairsIndiaHomepage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import ReviewsPage from "./modules/reviews-page/ReviewsPage";
import OrderHere from "./modules/order-here/OrderHere";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  function NavigateToPractice() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/homepage", { replace: true });
    }, []);
    return null;
  }
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<NavigateToPractice />} />
        <Route path="/homepage" element={<HairsIndiaHomepage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/order" element={<OrderHere />} />
      </Routes>
    </>
  );
}

export default App;
