import React, { useRef, useState, useEffect } from "react";
import "./AboutUs.css";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import about_banner from "../../assets/images/homepage/aboutpage/bgs.png";
import image1 from "../../assets/images/homepage/aboutpage/natarajar.png";
import image2 from "../../assets/images/homepage/aboutpage/who we are.png";
import image3 from "../../assets/images/homepage/aboutpage/3 Generations.png";
import image4 from "../../assets/images/homepage/aboutpage/customer satisfaction.png";
import image5 from "../../assets/images/homepage/aboutpage/temple.png";
import image6 from "../../assets/images/homepage/aboutpage/manufacturer and exporter.png";
import image7 from "../../assets/images/homepage/aboutpage/completely chemical.png";
import image8 from "../../assets/images/homepage/aboutpage/20 countries.png";
import { useNavigate } from "react-router-dom";
import imgm1 from "../../assets/images/homepage/aboutpage/mobile/imgm1.png";
import imgm2 from "../../assets/images/homepage/aboutpage/mobile/imgm2.png";
import imgm3 from "../../assets/images/homepage/aboutpage/mobile/imgm3.png";
import imgm4 from "../../assets/images/homepage/aboutpage/mobile/imgm4.png";
import imgm5 from "../../assets/images/homepage/aboutpage/mobile/imgm5.png";
import imgm6 from "../../assets/images/homepage/aboutpage/mobile/imgm6.png";
import prod_nat from "../../assets/images/homepage/aboutpage/n.png";
import AOS from "aos";
import "aos/dist/aos.css";
import mask_image from "../../assets/images/homepage/aboutpage/mask1.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import WOW from "wowjs";
import "animate.css";
import "wowjs/css/libs/animate.css";

function AboutUs() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll("[data-aos]");
  }, []);

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,

      resetAnimation: false,
    });
    wow.init();
  }, []);

  const navigate = useNavigate();
  const [navigationButtonHide, setNavigationButtonHide] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef(null);

  const datas = [
    {
      image: imgm2,
    },
    {
      image: imgm3,
    },
    {
      image: imgm4,
    },
    {
      image: imgm5,
    },
    {
      image: imgm6,
    },
    {
      image: imgm2,
    },
  ];

  return (
    <div id="who-we-are" className="relative">
      <div className="hairsindia-about-logo-mobile">
        <img src={prod_nat} className="hairsindia-prod-nat-mobile" />
        <img src={image2} alt="about_image" className="hairsindia-about-wwr" />
      </div>
      <div className="hairsindia-home-about-section relative">
        <img
          src={about_banner}
          className="about-banner-image"
          alt="about_banner"
        />
        <div
          className="absolute top-[60px] grid w-full"
          style={{ gridTemplateColumns: "repeat(4, 1fr)", height: "620px" }}
        >
          <span className="relative about-image-container-one">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-one"
            />

            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOut="fadeOutRight"
              duration={1}
              animatePreScroll={false}
              animateOnce={true}
            >
              <img
                src={image1}
                alt="about_image"
                className="hairsindia-about-image-one absolute top-0 right-0 left-[40px] max-w-[180px]"
              />
            </ScrollAnimation>
          </span>
          <span className="relative flex items-center about-image-container-two">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-two"
            />
            <ScrollAnimation
              animateIn="fadeIn"
              duration={4}
              animatePreScroll={false}
              animateOnce={true}
            >
              <img
                src={image2}
                alt="about_image"
                className=" hairsindia-about-image-two absolute right-[20px] bottom-[80px] m-auto max-w-[300px]"
              />
            </ScrollAnimation>
          </span>
          <span className="relative about-image-container-three">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-three"
            />
            <img
              src={image3}
              alt="about_image"
              className="hairsindia-about-image-three absolute top-0 right-0 left-0 m-auto max-w-[240px] wiggle wow flipInX"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
              data-wow-iteration="1"
            />
          </span>
          <span className="relative flex justify-center items-start about-image-container-four">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-four"
            />
            <img
              src={image4}
              alt="about_image"
              className="hairsindia-about-image-four max-w-[170px] wiggle wow flipInX"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            />
          </span>

          <span className="relative about-image-container-five">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-five"
            />
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOut="fadeOutRight"
              duration={1}
              animatePreScroll={false}
              animateOnce={true}
            >
              <img
                src={image5}
                alt="about_image"
                className="hairsindia-about-image-five absolute top-[-20px] right-[20px] max-w-[200px] max-h-[300px]"
              />
            </ScrollAnimation>
          </span>
          <span className="relative flex about-image-container-six">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-six"
            />
            <img
              src={image6}
              alt="about_image"
              className="hairsindia-about-image-six absolute top-[25px] right-[20px] max-w-[250px] wiggle  wow flipInX"
              data-wow-duration="2s"
              data-wow-delay="1s"
            />
          </span>
          <span className="relative flex about-image-container-seven">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-seven"
            />
            <img
              src={image7}
              alt="about_image"
              className="hairsindia-about-image-seven absolute max-w-[170px] right-[50px] bottom-[20px] wiggle wow flipInX"
              data-wow-duration="2s"
              data-wow-delay="1.2s"
            />
          </span>
          <span className="relative about-image-container-eight flex justify-center items-center">
            <img
              src={mask_image}
              alt="mask_image"
              className="abt-mask-image-eight"
            />
            <img
              src={image8}
              alt="about_image"
              className="hairsindia-about-image-eight max-w-[170px] mr-[20px] wiggle wow flipInX"
              data-wow-duration="2s"
              data-wow-delay="1.4s"
            />
          </span>
        </div>
      </div>

      <div className="hairsindia-home-about-section-mobile">
        <Swiper
          ref={swiperRef}
          edgeSwipeDetection
          style={{ width: "100%" }}
          modules={[Navigation, A11y]}
          pagination={false}
          scrollbar={false}
          spaceBetween={60}
          slidesPerView="auto"
          noSwiping={navigationButtonHide.isEnd}
          onBeforeInit={(item) => {
            if (item) {
              setNavigationButtonHide({
                isEnd: item?.isEnd,
                isBeginning: item?.isBeginning,
              });
            }
          }}
          onSlideChange={(item) => {
            setNavigationButtonHide({
              isEnd: item?.isEnd,
              isBeginning: item?.isBeginning,
            });
          }}
          roundLengths="true"
        >
          {datas?.map((card, index) => (
            <SwiperSlide className="foundation-card" key={index}>
              <img src={card?.image} className="wiggle wow flipInX" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
export default AboutUs;
