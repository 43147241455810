import { axiosService } from "../axios.service";
import { CHE_URLS } from "./orderhere.urls";

export const fetchProductsAll = () => {
  return axiosService.get(CHE_URLS.CHE_PRODUCT_NAME);
};

export const fetchTexture = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_TEXTURE, { ...params });
};

export const fetchLength = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_LENGTH, { ...params });
};

export const fetchLaceSize = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_LACESIZE, { ...params });
};

export const fetchLaceMaterial = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_LACEMATERIAL, { ...params });
};

export const fetchCapSize = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_CAPSIZE, { ...params });
};

export const fetchWeight = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_WEIGHT, { ...params });
};

export const fetchShapes = (params) => {
  return axiosService.get(CHE_URLS.CHE_GET_SHAPES, { ...params });
};

export const postApi = (data) => {
  return axiosService.post(CHE_URLS.CHE_SAVE_ORDERS, data);
};
export const fetchProductList = () => {
  return axiosService.get(CHE_URLS.PRODUCTS_LIST_ALL_GET);
};
